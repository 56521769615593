import {TrackingInfo} from "./TrackingInfo";
import {IPageViewTracker} from "./Trackers/IPageViewTracker";
import {IClickTracker} from "./Trackers/IClickTracker";
import {IInViewTracker} from "./Trackers/IInViewTracker";
import {IScrollReachTracker} from "./Trackers/IScrollReachTracker";
import {IVideoTracker} from "./Trackers/IVideoTracker";
import {IArticleTypeTracker} from "./Trackers/IArticleTypeTracker";

type AlreadySentEvent = [HTMLElement, Map<string, string[]>, number[]] | any;
type AlreadySentInViewEvent = [HTMLElement, "elements_in_view" | "elements_out_of_view", Map<string, string[]>, number[]];
type AlreadySentVideoEvent = [HTMLElement, string, Map<string, string[]>, number[]];

export class Tracking {
    static readonly TRACKING_PREFIX = "track"

    private pageViewTrackers: IPageViewTracker[] = Array<IPageViewTracker>();
    private clickTrackers: IClickTracker[] = Array<IClickTracker>();
    private inviewTrackers: IInViewTracker[] = Array<IInViewTracker>();
    private scrollReachTrackers: IScrollReachTracker[] = Array<IScrollReachTracker>();
    private readonly trackingInfo: TrackingInfo;
    private videoTrackers: IVideoTracker[] = Array<IVideoTracker>();
    private articleTypeTrackers: IArticleTypeTracker[] = Array<IArticleTypeTracker>();

    // A flag signaling if the pv has already been triggered in case a tracker is added after the pv.
    private pageViewTriggered: boolean = false;
    // A list of already sent in-view events for use when subscribing a tracker after the first in-view events are sent.
    private inviewEventsAlreadySent: AlreadySentInViewEvent[] = Array<AlreadySentEvent>();
    // As for in-views events.
    private clickEventsAlreadySent: AlreadySentEvent[] = Array<AlreadySentEvent>();
    private scrollReachEventsAlreadySent: AlreadySentEvent[] = Array<AlreadySentEvent>();
    private videoEventsAlreadySent: AlreadySentVideoEvent[] = Array<AlreadySentVideoEvent>();
    private articleTypeEventsAlreadySent: AlreadySentEvent[] = Array<AlreadySentEvent>();
    private pageViewTimestamp: number;

    private articleTypeTriggered: boolean = false;


    constructor() {
        this.trackingInfo = new TrackingInfo();
    }

    public pageView(): void {
        if (!this.pageViewTriggered) {
            this.pageViewTrackers.forEach(tracker => tracker.trackPageView(this.trackingInfo));

            this.pageViewTimestamp = new Date().getTime();
            this.pageViewTriggered = true;
        }
    }

    public subscribePageView(tracker: IPageViewTracker) {
        this.pageViewTrackers.push(tracker);

        //Trigger page view, because it have already been triggered.
        if (this.pageViewTriggered) {
            tracker.trackPageView(this.trackingInfo)
        }
    }

    public subscribeClick(tracker: IClickTracker) {
        this.clickTrackers.push(tracker);
        this.clickEventsAlreadySent.forEach(ev => tracker.trackClick(this.trackingInfo, ev[0], ev[1]));
    }

    public subscribeInView(tracker: IInViewTracker) {
        this.inviewTrackers.push(tracker);
        this.inviewEventsAlreadySent.forEach(ev => tracker.trackInView(this.trackingInfo, ev[0], ev[1], ev[2], ev[3]));
    }

    public subscribeScrollReach(tracker: IScrollReachTracker) {
        this.scrollReachTrackers.push(tracker);

        this.scrollReachEventsAlreadySent.forEach(ev => tracker.trackScrollReach(this.trackingInfo, ev[0], ev[1], ev[2]));
    }

    public subscribeVideo(tracker: IVideoTracker) {
        this.videoTrackers.push(tracker);
        this.videoEventsAlreadySent.forEach(ev => tracker.trackVideo(this.trackingInfo, ev[0], ev[1], ev[2], ev[3]));
    }

    public subscribeArticleType(tracker: IArticleTypeTracker) {
        this.articleTypeTrackers.push(tracker);
        this.articleTypeEventsAlreadySent.forEach(ev => tracker.trackArticleType(this.trackingInfo, ev[1]));
    }

    public click(rootElement: HTMLElement): void {
        rootElement.addEventListener('click', (event: MouseEvent) => {
            const clickedElement = event.target as HTMLElement;
            const trackingAttributes = Tracking.getTrackingAttributes(rootElement, clickedElement);

            const hasClickedOnInlineVideoElement =
                trackingAttributes.has("label") &&
                trackingAttributes.get("label").includes("inline-video");

            if (hasClickedOnInlineVideoElement) {
                return;
            }

            this.clickEventsAlreadySent.push([clickedElement, trackingAttributes, this.getArticleIdsInZone(clickedElement)]);
            this.clickTrackers.forEach(tracker => tracker.trackClick(this.trackingInfo, clickedElement, trackingAttributes));
        })
    }


    public getArticleIdsInZone(elem: HTMLElement): number[] {
        let articles = Array.from(elem.querySelectorAll('.article-teaser[data-track-label], .tw-article-teaser[data-track-label], .content-marketing-teaser[data-track-label], .article-teaser__related__item[data-track-label], .c-daily-liveblog__article[data-track-label]'))
        // We need to remove duplicates since, for related articles we make two articles,
        // One for mobile and one for desktop, therefore we want to avoid tracking the same article twice

        return this.removeDuplicates(articles)
    }

    private removeDuplicates(array: Element[]): number[] {
        const result = [];
        array.forEach((el: Element) => {
            let elementId = parseInt(el.getAttribute('data-track-label'));
            if (!result.includes(elementId) && !isNaN(elementId)) {
                result.push(elementId);
            }
        })
        return result;
    }

    public inview(element: HTMLElement, options: IntersectionObserverInit): void {
        const self = this

        const observer = window['isArticlePage']
            ? new IntersectionObserver((entries, intersectionObserver) => {
                entries.forEach((entry) => {
                    const inViewElement = entry.target as HTMLElement;
                    const articleIds = this.getArticleIdsInZone(inViewElement);
                    const trackingAttributes = Tracking.getTrackingAttributes(document.body, inViewElement);
                    const elementHeight = entry.boundingClientRect.height;
                    const viewportHeight = entry.rootBounds.height;
                    if (entry.isIntersecting) {
                        const isCompletelyInView = elementHeight <= viewportHeight && entry.intersectionRatio === 1
                        const viewPortHeightIsBiggerThanElementHeight = viewportHeight >= elementHeight
                        if (viewPortHeightIsBiggerThanElementHeight) {
                            if (isCompletelyInView) {
                                if (inViewElement.dataset.trackLabel === "article_bottom_track") {

                                    const experimentLabel = window.authState.subscription.isActive ? "article_bottom_environment_inview" : "article_bottom_environment_inview_guest"

                                    window.hj('event', experimentLabel);
                                }
                                this.inviewEventsAlreadySent.push([inViewElement, "elements_in_view", trackingAttributes, articleIds]);
                                this.inviewTrackers.forEach(tracker => tracker.trackInView(this.trackingInfo, inViewElement, "elements_in_view", trackingAttributes, articleIds));
                                intersectionObserver.unobserve(inViewElement);

                                // We track elements out of view, to get the timestamp, so we can calculate the duration in which the element was in view.
                                const elementFitInViewport = new IntersectionObserver((entries, intersectionObserver) => {
                                    entries.forEach((entry) => {
                                        const hasGoneCompletelyOutOfView = entry.intersectionRatio === 0
                                        if (hasGoneCompletelyOutOfView) {
                                            this.inviewEventsAlreadySent.push([inViewElement, "elements_out_of_view", trackingAttributes, articleIds]);
                                            this.inviewTrackers.forEach(tracker => tracker.trackInView(this.trackingInfo, inViewElement, "elements_out_of_view", trackingAttributes, articleIds));
                                            intersectionObserver.unobserve(inViewElement);
                                        }
                                    })
                                }, options);
                                elementFitInViewport.observe(element);
                            }
                        } else {
                            // This tracking is the old tracking and is used on frontpage.
                            const trackInviewImmediately = new IntersectionObserver((entries, intersectionObserver) => {
                                entries.forEach((entry) => {
                                    if (entry.isIntersecting) {
                                        const inViewElement = entry.target as HTMLElement;
                                        const articleIds = self.getArticleIdsInZone(inViewElement);
                                        const trackingAttributes = Tracking.getTrackingAttributes(document.body, inViewElement);

                                        self.inviewEventsAlreadySent.push([inViewElement, "elements_in_view", trackingAttributes, articleIds]);
                                        self.inviewTrackers.forEach(tracker => tracker.trackInView(self.trackingInfo, inViewElement, "elements_in_view", trackingAttributes, articleIds));
                                        intersectionObserver.unobserve(inViewElement);
                                    }
                                })
                            }, options);
                            trackInviewImmediately.observe(element);
                        }
                    }
                })
            }, options)
            : new IntersectionObserver((entries, intersectionObserver) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const inViewElement = entry.target as HTMLElement;
                        const articleIds = this.getArticleIdsInZone(inViewElement);
                        const trackingAttributes = Tracking.getTrackingAttributes(document.body, inViewElement);
                        this.inviewEventsAlreadySent.push([inViewElement, "elements_in_view", trackingAttributes, articleIds]);
                        this.inviewTrackers.forEach(tracker => tracker.trackInView(this.trackingInfo, inViewElement, "elements_in_view", trackingAttributes, articleIds));
                        intersectionObserver.unobserve(inViewElement);
                    }
                })
            }, options);

        observer.observe(element);

        function listener() {
            const trackingAttributes = Tracking.getTrackingAttributes(document.body, element);
            const articleIds = self.getArticleIdsInZone(element);
            const isElementTallerThanViewport = element.getBoundingClientRect().height > window.innerHeight
            const hasReachedBottomOfElement = Math.floor(element.getBoundingClientRect().bottom) <= window.innerHeight;
            if (isElementTallerThanViewport && hasReachedBottomOfElement) {
                self.inviewEventsAlreadySent.push([element, "elements_out_of_view", trackingAttributes, articleIds]);
                self.inviewTrackers.forEach(tracker => tracker.trackInView(self.trackingInfo, element, "elements_out_of_view", trackingAttributes, articleIds));
                window.removeEventListener('scroll', listener)
                window.removeEventListener('resize', listener)
            }
        }

        if (window['isArticlePage']) {
            window.addEventListener('scroll', listener)
            window.addEventListener('resize', listener)
        }
    }


    /*
        Tracks scroll reach inside a given container.
     */
    public scrollReachInit(element: HTMLElement) {
        const targets = element.querySelectorAll("[data-js-sel='scroll-reach-target']");
        const eventName = element.getAttribute("data-track-scroll-reach-event-name");

        const observer = new IntersectionObserver((entries, intersectionObserver) => {
            entries.forEach((entry) => {

                if (entry.isIntersecting) {
                    const target = entry.target as HTMLElement;
                    const scrollReachValue = target.getAttribute('data-scroll-reach-value');

                    if (scrollReachValue) {

                        intersectionObserver.unobserve(entry.target);

                        this.scrollReachEventsAlreadySent.push([scrollReachValue, this.pageViewTimestamp, eventName]);

                        this.scrollReachTrackers.forEach(tracker => tracker.trackScrollReach(this.trackingInfo, scrollReachValue, this.pageViewTimestamp, eventName));
                    }

                }
            })
        });

        targets.forEach((target) => {
            observer.observe(target);
        })
    }

    public video(video: HTMLElement, player: any): void {
        const articleIds = this.getArticleIdsInZone(video);
        const trackingAttributes = Tracking.getTrackingAttributes(document.body, video);

        const elementId = video.getAttribute('data-track-label') ?? "inline-video"

        let videoClickPlay = false;

        let videoProgress25 = false;
        let videoProgress50 = false;
        let videoProgress75 = false;
        let videoProgress100 = false;

        player.addEventListener('play', () => {
            if (!videoClickPlay) {
                videoClickPlay = true
                this.videoTrackers.forEach(tracker => {
                    this.videoEventsAlreadySent.push([video, elementId + '_play', trackingAttributes, articleIds])
                    tracker.trackVideo(this.trackingInfo, video, elementId + '_play', trackingAttributes, articleIds);
                })
            }
        })

        player.addEventListener('volumechange', () => {
            if (player.muted) {
                this.videoEventsAlreadySent.push([video, elementId + '_muted', trackingAttributes, articleIds])
                this.videoTrackers.forEach(tracker => tracker.trackVideo(this.trackingInfo, video, elementId + '_muted', trackingAttributes, articleIds))
            } else {
                this.videoEventsAlreadySent.push([video, elementId + '_unmuted', trackingAttributes, articleIds])
                this.videoTrackers.forEach(tracker => tracker.trackVideo(this.trackingInfo, video, elementId + '_unmuted', trackingAttributes, articleIds))
            }
        })

        player.addEventListener('timeupdate', () => {
            const currentTime = player.currentTime
            const duration = player.duration
            const percent = duration > 0 ? (currentTime / duration) * 100 : 0;

            if (percent >= 25 && !videoProgress25) {
                this.videoEventsAlreadySent.push([video, elementId + '_25%', trackingAttributes, articleIds])
                this.videoTrackers.forEach(tracker => tracker.trackVideo(this.trackingInfo, video, elementId + '_25%', trackingAttributes, articleIds))
                videoProgress25 = true;
            }

            if (percent >= 50 && !videoProgress50) {
                this.videoEventsAlreadySent.push([video, elementId + '_50%', trackingAttributes, articleIds])
                this.videoTrackers.forEach(tracker => tracker.trackVideo(this.trackingInfo, video, elementId + '_50%', trackingAttributes, articleIds))
                videoProgress50 = true;
            }

            if (percent >= 75 && !videoProgress75) {
                this.videoEventsAlreadySent.push([video, elementId + '_75%', trackingAttributes, articleIds])
                this.videoTrackers.forEach(tracker => tracker.trackVideo(this.trackingInfo, video, elementId + '_75%', trackingAttributes, articleIds))
                videoProgress75 = true;
            }

            if (percent === 100 && !videoProgress100) {
                this.videoEventsAlreadySent.push([video, elementId + '_100%', trackingAttributes, articleIds])
                this.videoTrackers.forEach(tracker => tracker.trackVideo(this.trackingInfo, video, elementId + '_100%', trackingAttributes, articleIds))
                videoProgress100 = true;
            }
        });
    }

    public articleType(): void {
        document.addEventListener("DOMContentLoaded", () => {
            let articleType: string;

            if (window['isFeatureArticlePage']) {
                articleType = "feature-article"
            } else {
                articleType = "default-article"
            }

            const isArticlePage = window['isArticlePage']

            if (!this.articleTypeTriggered && isArticlePage) {
                this.articleTypeEventsAlreadySent.push([document.body, articleType])
                this.articleTypeTrackers.forEach(tracker => tracker.trackArticleType(this.trackingInfo, articleType))
                this.articleTypeTriggered = true;
            }
        });
    }

    public static getTrackingAttributes(rootElement: HTMLElement, clickedElement: HTMLElement | null): Map<string, string[]> {
        let target = clickedElement;
        const map = new Map<string, string[]>()

        // Go up in DOM hierarchy until the root element and collect all tracking data attribute(s).
        while (target !== rootElement && target) {
            const trackingKeys = Tracking.trackingAttributesKeys(target.dataset)

            if (trackingKeys.length > 0) {
                trackingKeys.forEach(key => {
                    let newKey = key.replace(Tracking.TRACKING_PREFIX, "").toLowerCase()
                    let value = target!.dataset[key]
                    let currentValue = map.get(newKey)
                    if (!!value) {
                        if (currentValue) {
                            currentValue.push(value)
                            map.set(newKey, currentValue)
                        } else {
                            map.set(newKey, Array(value))
                        }
                    }
                })

            }
            target = target.parentElement;
        }
        return map
    }

    public static trackingAttributesKeys(dataSet: DOMStringMap): string[] {
        const keys = Object.keys(dataSet)
        return keys.filter(a => a.startsWith(Tracking.TRACKING_PREFIX));
    }
}

